import { Grid, Menu, Segment } from '@jvs-group/jvs-mairistem-composants';
import React from 'react';
import '@/src/components/Parametres/parametres.css';
import { GabaritList } from '@jvs-group/jvs-mairistem-comptabilite';
import { TYPE_GABARIT } from '@jvs-group/jvs-mairistem-finances-utils';
import EtiquetteListe from '@/src/components/Parametres/EtiquetteListe/EtiquetteListe';
import LeviiaPDE from '@/src/components/Parametres/LeviiaPDE/LeviiaPDE';

const TAB = {
  ETIQUETTES: 'etiquettes',
  GABARIT_SUBVENTION: 'gabarit_subvention',
  GABARIT_PROJET: 'gabarit_projet',
  LEVIIA: 'porte_documents_leviia',
};

const Parametres = () => {
  const [currentTab, setCurrentTab] = React.useState(TAB.ETIQUETTES);

  const handleOpenMenu = (e, { name }) => {
    setCurrentTab(name);
  };

  const isDev = process.env.APP_ISDEV === 'true';
  // TODO: affichage si env de dev => à changer

  return (
    <Grid id="parametres">
      <Grid.Row>
        <Grid.Column
          widescreen={2}
          largeScreen={2}
          computer={2}
          tablet={5}
          mobile={16}
          textAlign="center"
          className="wrapper"
          stackable
        >
          <Menu pointing vertical fluid>
            <Menu.Item
              name={TAB.ETIQUETTES}
              onClick={handleOpenMenu}
              active={currentTab === TAB.ETIQUETTES}
            />

            <Menu.Item
              name={TAB.GABARIT_SUBVENTION}
              onClick={handleOpenMenu}
              active={currentTab === TAB.GABARIT_SUBVENTION}
            />

            <Menu.Item
              name={TAB.GABARIT_PROJET}
              onClick={handleOpenMenu}
              active={currentTab === TAB.GABARIT_PROJET}
            />
            {
              isDev
              && (
                <Menu.Item
                  name={TAB.LEVIIA}
                  onClick={handleOpenMenu}
                  active={currentTab === TAB.LEVIIA}
                />
              )
            }
          </Menu>
        </Grid.Column>
        <Grid.Column
          widescreen={14}
          largeScreen={14}
          computer={14}
          tablet={11}
          mobile={16}
          stretched
          id="menu-content"
        >
          <Segment attached="bottom">
            {currentTab === TAB.ETIQUETTES && (<EtiquetteListe />)}
            {currentTab === TAB.GABARIT_SUBVENTION && (
              <div className="gabarit">
                <GabaritList type={TYPE_GABARIT.SUBVENTION_PERCUE} />
              </div>
            )}
            {currentTab === TAB.GABARIT_PROJET && (
              <div className="gabarit">
                <GabaritList type={TYPE_GABARIT.PROJET} />
              </div>
            )}
            {currentTab === TAB.LEVIIA && (<LeviiaPDE />)}
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>

  );
};

export default Parametres;
