import React from 'react';

import {
  Form,
  Loader,
} from '@jvs-group/jvs-mairistem-composants';
import { request } from '@jvs-group/jvs-mairistem-store';
import { toast } from 'react-toastify';
import { getErrorMessage, TemplateRequestGet } from '@jvs-group/jvs-mairistem-finances-utils';
import SousDossiersListe from '@/src/components/Parametres/LeviiaPDE/SousDossiersListe';
import DossierRacine from '@/src/components/Parametres/LeviiaPDE/DossierRacine';
import ReglesNommages from '@/src/components/Parametres/LeviiaPDE/ReglesNommages';
import { Parametres } from '@/src/interfaces/parametre';

const LeviiaPDE = () => {
  const [parametresLeviia, setParametresLeviia] = React.useState<Parametres>(null);
  const [loading, setLoading] = React.useState(true);

  const loadParametres = async () => {
    setLoading(true);

    try {
      const { data } = await request.get<any, TemplateRequestGet<Parametres>>(
        '/api/finances/subventionsPercues/parametres?withLeviaaSousDossiers=true',
      );
      setParametresLeviia(data[0]);
    } catch (e) {
      toast.error(getErrorMessage(e, 'Erreur lors de la récuperation des paramètres'));
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    loadParametres();
  }, []);

  const handleChange = async (e, { name, value }) => {
    try {
      const updatedData = await request.put<Partial<Parametres>, Parametres>(
        `/api/finances/subventionsPercues/parametres/${parametresLeviia.identifiant}`,
        { [name]: value },
      );
      setParametresLeviia(updatedData);
    } catch (e) {
      toast.error(getErrorMessage(e, `Erreur lors de la modification du champ ${name}`));
    }
  };

  return (
    <div>
      { loading ? <Loader />
        : (
          <>
            <Form.Checkbox
              checked={parametresLeviia.leviiaActif}
              value={!parametresLeviia.leviiaActif}
              name="leviiaActif"
              label='Activer la section "Porte document Leviia" sur les fiches Projet'
              onChange={handleChange}
            />
            {parametresLeviia.leviiaActif && (
            <>
              <DossierRacine onChange={handleChange} nomDossierRacine={parametresLeviia.leviiaRacine} />
              <ReglesNommages onChange={handleChange} codeNommage={parametresLeviia.leviiaCodeNommage} />
              <SousDossiersListe
                sousDossiers={parametresLeviia.leviiaSousDossiers}
                id={parametresLeviia?.identifiant}
              />
            </>
            )}

          </>
        )}
    </div>
  );
};

export default LeviiaPDE;
