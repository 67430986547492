// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import React from 'react';
import { StoreProvider } from '@jvs-group/jvs-mairistem-store';

import { Router, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { SemanticToastContainer } from 'react-semantic-toasts';

import store from '@/src/redux/store';

const Context = ({
  children, path, history, scope,
}) => (
  <StoreProvider store={store}>
    {scope === 'content' && (
      <>
        <SemanticToastContainer position="top-right" />
        <ToastContainer />
      </>
    )}

    <Router history={history}>
      <Route path={path}>
        {
          React.isValidElement(children)
            ? children
            : React.createElement(children)
        }
      </Route>
    </Router>
  </StoreProvider>
);

export default Context;
