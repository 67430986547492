import { ListeTable, ListeTableContainer } from '@jvs-group/jvs-mairistem-liste';
import { request } from '@jvs-group/jvs-mairistem-store';
import React from 'react';
import { isEmpty, omit } from 'lodash';
import { getErrorMessage, TemplateRequestGet } from '@jvs-group/jvs-mairistem-finances-utils';
import { toast } from 'react-toastify';
import EtiquetteRow from '@/src/components/Parametres/EtiquetteListe/EtiquetteRow';
import { Etiquette } from '@/src/interfaces/etiquette';

const EtiquetteListe = () => {
  const [etiquettes, setEtiquettes] = React.useState<Etiquette[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errors, setErrors] = React.useState([]);

  const loadEtiquettes = async () => {
    setIsLoading(true);

    try {
      const { data } = await request.get<any, TemplateRequestGet<Etiquette>>(
        '/api/finances/subventionsPercues/etiquettes',
      );
      setEtiquettes(data);
    } catch (e) {
      toast.error(getErrorMessage(e, 'Erreur lors de la récuperation des étiquettes'));
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    loadEtiquettes();
  }, []);

  const handleOnLoad = () => {
    loadEtiquettes();
  };

  const checkEtiquette = (item) => {
    const errs = [];
    if (isEmpty(item?.libelle)) {
      errs.push({
        itemId: item?.identifiant,
        field: 'libelle',
        message: 'Le libelle est obligatoire',
      });
    }

    return errs;
  };

  const createEtiquette = async (item: Etiquette) => {
    try {
      const createdEtiquette = await request.post<Partial<Etiquette>, Etiquette>(
        '/api/finances/subventionsPercues/etiquettes',
        omit(item, ['identifiant']),
      );
      return createdEtiquette;
    } catch {
      setErrors([{
        itemId: item?.identifiant,
        field: 'libelle',
        message: "Erreur lors de la création de l'étiquette",
      },
      ]);
      return false;
    }
  };

  const updateEtiquette = async (item: Etiquette) => {
    try {
      const updatedEtiquette = await request.put<Partial<Etiquette>, Etiquette>(
        `/api/finances/subventionsPercues/etiquettes/${item?.identifiant}`,
        item,
      );
      setEtiquettes((state) => ([...state.filter((et) => et.identifiant !== item?.identifiant), updatedEtiquette]));
      return true;
    } catch {
      setErrors([{
        itemId: item?.identifiant,
        field: 'libelle',
        message: "Erreur lors de la modification de l'étiquette",
      },
      ]);
      return false;
    }
  };

  const handleDelete = async (item: Etiquette) => {
    try {
      await request.delete(`/api/finances/subventionsPercues/etiquettes/${item?.identifiant}`);
      setEtiquettes((state) => ([...state.filter((et) => et.identifiant !== item?.identifiant)]));
      return true;
    } catch {
      setErrors([{
        itemId: item?.identifiant,
        field: 'libelle',
        message: "Erreur lors de la suppression de l'étiquette",
      },
      ]);
      return false;
    }
  };

  const handleValidate = (item, status) => {
    const errs = checkEtiquette(item);
    if (errs?.length > 0) {
      setErrors(errs);
      return false;
    }

    setErrors([]);

    switch (status) {
      case 'creating':
        return createEtiquette(item);
      case 'updating':
        return updateEtiquette(item);
      default:
    }

    return true;
  };

  const handleCancelClick = () => { setErrors([]); };

  return (
    <ListeTableContainer
      items={etiquettes}
      onValidateClick={handleValidate}
      onDeleteClick={handleDelete}
      allowModifications
      allowDeletions
      allowAdditions
      onCancelClick={handleCancelClick}
      errors={errors}
    >
      <ListeTable
        count={etiquettes?.length || 0}
        loading={isLoading}
        onLoad={handleOnLoad}
        perPage={etiquettes?.length || 0}
      >
        <ListeTable.Header>
          <ListeTable.Column name="libelle" width={16}>Libellé</ListeTable.Column>
        </ListeTable.Header>
        {
          // @ts-ignore
          (etiquette, invalidate, rowProps, utilities) => (
            <EtiquetteRow
              key={etiquette?.identifiant}
              rowProps={rowProps}
              utilities={utilities}
              etiquette={etiquette}
            />
          )
        }
      </ListeTable>
    </ListeTableContainer>
  );
};

export default EtiquetteListe;
