import { Card, Input } from '@jvs-group/jvs-mairistem-composants';
import { ListeTable, ListeTableContainer } from '@jvs-group/jvs-mairistem-liste';
import { request } from '@jvs-group/jvs-mairistem-store';
import { isEmpty, omit } from 'lodash';
import React from 'react';
import '@/src/components/Parametres/LeviiaPDE/sousDossierListe.less';
import { SousDossier } from '@/src/interfaces/sousDossier';

interface SousDossiersListeProps {
  id: number
  sousDossiers: SousDossier[],
}

const SousDossiersListe = ({ id, sousDossiers }: SousDossiersListeProps) => {
  const [errors, setErrors] = React.useState([]);
  const [sousDossiersListe, setSousDossiersListe] = React.useState<SousDossier[]>(sousDossiers ?? []);

  const createSousDossier = async (item: SousDossier) => {
    try {
      const createdItem = await request.post<Partial<SousDossier>, SousDossier>(
        '/api/finances/subventionsPercues/parametres/sousDossiers',
        omit({ ...item, identifiantParametre: id }, ['identifiant']),
      );
      return createdItem;
    } catch {
      setErrors([{
        itemId: item?.identifiant,
        field: 'libelle',
        message: "Erreur dans la création de l'élément",
      }]);
      return false;
    }
  };

  const updateSousDossier = async (item: Partial<SousDossier>) => {
    try {
      const updatedItem = await request.put<Partial<SousDossier>, SousDossier>(
        `/api/finances/subventionsPercues/parametres/sousDossiers/${item.identifiant}`,
        item,
      );
      setSousDossiersListe(
        [...sousDossiersListe.filter((sousDossier: SousDossier) => sousDossier.identifiant !== item?.identifiant),
          updatedItem],
      );
      return true;
    } catch {
      setErrors([{
        itemId: item?.identifiant,
        field: 'libelle',
        message: "Erreur dans la modification de l'élément",
      }]);
      return false;
    }
  };

  const handleDelete = async (item) => {
    try {
      await request.delete(
        `/api/finances/subventionsPercues/parametres/sousDossiers/${item.identifiant}`,
        item,
      );
      setSousDossiersListe(
        [...sousDossiersListe.filter((sousDossier) => sousDossier.identifiant !== item?.identifiant)],
      );
      return true;
    } catch {
      setErrors([{
        itemId: item?.identifiant,
        field: 'libelle',
        message: "Erreur dans la suppression de l'élément",
      }]);
      return false;
    }
  };

  const checkSousDossiers = (item: SousDossier) => {
    const errs = [];
    if (isEmpty(item?.libelle)) {
      errs.push({
        itemId: item?.identifiant,
        field: 'libelle',
        message: 'Le libelle est obligatoire',
      });
    }

    return errs;
  };

  const handleValidate = (item, status) => {
    const errs = checkSousDossiers(item);
    if (errs?.length > 0) {
      setErrors(errs);
      return false;
    }
    setErrors([]);

    switch (status) {
      case 'creating':
        return createSousDossier(item);
      case 'updating': {
        return updateSousDossier(item);
      }
      default:
        break;
    }

    return true;
  };

  return (
    <Card fluid>
      <Card.Content>
        <Card.Header>
          Sous dossiers à créer pour chaque projets
        </Card.Header>
        <div className="sousDossierList">
          <ListeTableContainer
            items={sousDossiersListe}
            onValidateClick={handleValidate}
            onDeleteClick={handleDelete}
            onCancelClick={() => setErrors([])}
            allowModifications
            allowDeletions
            allowAdditions
            errors={errors}
          >
            <ListeTable
              count={sousDossiersListe?.length || 0}
              perPage={sousDossiersListe?.length || 0}
            >
              <ListeTable.Header>
                <ListeTable.Column name="libelle" width={16}>Libellé</ListeTable.Column>
              </ListeTable.Header>
              {
                // @ts-ignore
                (sousDossier: SousDossier, invalidate, rowProps, utilities) => {
                  const handleChange = (e, { name, value }) => {
                    utilities?.onChange({ [name]: value });
                  };

                  return (
                    <ListeTable.Row
                      key={sousDossier?.identifiant}
                      {...rowProps}
                    >
                      <ListeTable.Cell
                        name="libelle"
                        stretched
                      >
                        <Input
                          name="libelle"
                          value={sousDossier?.libelle}
                          onChange={handleChange}
                          fluid
                          transparent
                          autoFocus={utilities?.autoFocus}
                        />
                      </ListeTable.Cell>
                    </ListeTable.Row>
                  );
                }
              }
            </ListeTable>
          </ListeTableContainer>
        </div>
      </Card.Content>
    </Card>

  );
};

export default SousDossiersListe;
